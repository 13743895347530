
import { defineComponent, reactive } from "vue";
import Resume from "@/components/Resume/Resume.vue";
import ContentBlock from "@/components/ContentBlock/ContentBlock.vue";
import ResumeService from "@/services/resume";
import { Resume as ResumeType } from "@/types/ResumeTypes";
import Error from "@/components/Error/Error.vue";
import Button from "@/components/Button/Button.vue";
import DownloadIcon from "@/assets/download-simple.svg";
import PrinterIcon from "@/assets/printer.svg";

interface State {
  resume: ResumeType | null;
  isLoading: boolean;
  errorMessage: {
    title: string;
    description: string;
  } | null;
  showDownload: boolean;
}

export default defineComponent({
  name: "page-about",
  setup() {
    const state: State = reactive({
      resume: null,
      isLoading: true,
      errorMessage: null,
      showDownload: true,
    });

    const print = () => {
      window.print();
    };

    fetch(`${process.env.VUE_APP_BACKEND}/ping`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          state.showDownload = true;
        }
      })
      .catch(() => {
        state.showDownload = false;
      });

    ResumeService.getResume()
      .then((resume) => {
        state.resume = resume;
      })
      .catch(() => {
        console.error("Loading resume failed");
        state.errorMessage = {
          title: "Oj da, dette skulle ikke skje",
          description: "Feilen er blitt rapportert, prøv igjen snart",
        };
      })
      .finally(() => {
        state.isLoading = false;
      });

    return {
      downloadURL: `${process.env.VUE_APP_BACKEND}/sh/portfolio/download/cv`,
      print,
      state,
    };
  },
  components: {
    Resume,
    ContentBlock,
    Error,
    Button,
    PrinterIcon,
    DownloadIcon,
  },
});
